// @file state and actions for the "Analytics" panel
import { captureFetchException } from '@@/bits/error_tracker'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfaceStore } from '@@/pinia/surface'
import PadletApi from '@@/surface/padlet_api'
import { defineStore } from 'pinia'
import { ref } from 'vue'

interface WallSummary {
  wishes: number
  reactions: number
  comments: number
  contributors: number
  privacy: string
  storage_used: string
  format: string
}

export enum WallSummaryStatus {
  Loading = 'Loading',
  Errored = 'Errored',
  Completed = 'Completed',
}

export const useSurfaceAnalyticsPanelStore = defineStore('surfaceAnalyticsPanelStore', () => {
  const surfaceStore = useSurfaceStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  const xSurfaceAnalyticsPanel = ref(false)
  const wallSummary = ref<WallSummary | null>(null)
  const wallSummaryStatus = ref<WallSummaryStatus>(WallSummaryStatus.Loading)

  const showSurfaceAnalyticsPanel = (): void => {
    xSurfaceAnalyticsPanel.value = true
  }

  const closeSurfaceAnalyticsPanel = (): void => {
    xSurfaceAnalyticsPanel.value = false
    wallSummary.value = null
    wallSummaryStatus.value = WallSummaryStatus.Loading
  }

  const fetchWallSummary = async (): Promise<void> => {
    try {
      const wallSummaryData = await PadletApi.Wall.fetchSummary({ hashid: surfaceStore.wallHashid })
      wallSummary.value = wallSummaryData
      wallSummaryStatus.value = WallSummaryStatus.Completed
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      wallSummaryStatus.value = WallSummaryStatus.Errored

      captureFetchException(e, { source: 'SurfaceAnalyticsPanelFetchWallSummary' })
    }
  }

  return {
    // State
    xSurfaceAnalyticsPanel,
    wallSummary,
    wallSummaryStatus,

    // Actions
    fetchWallSummary,
    showSurfaceAnalyticsPanel,
    closeSurfaceAnalyticsPanel,
  }
})
